/** Bootstrap the application * */
/** Load application modules * */
import Hammer from 'hammerjs'; // Touch-events library
import Vue from 'vue';
import iframeResize from 'iframe-resizer/js/iframeResizer';

/**
 * Vue LazyLoad handles lazy loading of images to improve page performance.
 */
import VueLazyload from 'vue-lazyload';

/**
 * VeeValidate is a plugin for Vue.js that allows you to validate input fields,
 * and display errors, in an easy and powerful way.
 */
import VeeValidate from 'vee-validate';

require('./bootstrap');

Vue.use(VueLazyload);

/**
 * EventBus used to communicate between the webapplication components
 * */
window.EventBus = new Vue();

Vue.use(VeeValidate);
/** Load template filters * */
require('./filters');

/** Load custom Vue components * */
require('./components/slider');
require('./components/media-slider');
require('./components/overview-datepicker');
require('./components/restaurant-menu');
require('./components/festival-item');
require('./components/corona-popup');
require('./components/event-form-component');
require('./components/poster-carousel');
require('./components/video-player');
require('./components/view-toggle');
require('./components/education-form-component');

/** Schedulers */
require('./components/schedulers/main');
require('./components/schedulers/event');
require('./components/schedulers/serie');
require('./components/schedulers/festival');

/** The root instance */
window.App = new Vue({
    /**
     * The Vue.js root instance element
     */
    el: '#app',

    /**
     * Register a custom delimiter because the default conflicts with CraftCMS's Twig
     */
    delimiters: ['${', '}'],

    /**
     * Root instance reactive data variables
     *
     * @return {Object}
     */
    data() {
        return {
            showMenu: false,
            showLanguageSelect: false,
            showSearch: false,
            showFullTimeTickets: false,
            showOverlay: false,
            showTicketOverlay: false,
            yearDropdown: false,
            monthDropdown: false,
            mainOption: false,
            subOption: false,
            showPressSubscribe: false,
            cartAmount: null,
            debug: false,
            reversed: true,
            showCookieMessage: false,
            newsletterCheckbox: false,
            newsletterSubmitting: false,
        };
    },

    created() {
        // const overlay = this.getCookie('cookie-message');
        // if (!overlay) {
        //     this.showCookieMessage = true;
        //     setTimeout(() => {
        //         document.getElementById('cookie-message').classList.add('visible');
        //     }, 300);
        // }

        if (!Modernizr.picture) {
            // Picture element HTML5 shiv
            document.createElement('picture');

            // Inject the polyfill
            const picturefill = document.createElement('script');
            picturefill.src = 'https://cdn.rawgit.com/scottjehl/picturefill/3.0.2/dist/picturefill.min.js';
            picturefill.setAttribute('type', 'text/javascript');
            picturefill.async = true;
            picturefill.defer = true;
            document.head.appendChild(picturefill);
        }
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        // Disable initialization of headroom, cause we removed the dropdowns for mobile views
        // const dropdowns = document.querySelector('.fixed-dropdowns');
        // if (dropdowns) {
        //     const headroom = new Headroom(dropdowns);
        //     headroom.init();
        // }

        // Fetch cart amount for session
        this.getCartAmount();

        window.addEventListener('message', (event) => {
            if (event.origin !== 'https://tickets.lumiere.nl') {
                return;
            }

            const modal = document.getElementById('tickets-overlay');

            if (event.data.pageCode === 'shop_cart' && modal) {
                modal.scrollTo({ top: 0, behavior: 'smooth' });
            }
        });

        // Register ticketholder toggle event
        EventBus.$on('toggle-ticketholder', (data) => {
            this.openTicketHolder(data);
        });

        // Listen for iframe
        const cart = document.getElementById('cart-iframe');
        if (cart) {
            iframeResize({ autoResize: true }, cart);
        }

        const pass = document.getElementById('lumiere-pas-iframe');
        if (pass) {
            iframeResize({ autoResize: true }, pass);
        }

        const myLumiere = document.getElementById('mylumiere-iframe');
        if (myLumiere) {
            iframeResize({ autoResize: true }, myLumiere);
        }

        // Setting the offset of the zenscroll plugin
        zenscroll.setup(null, 80);
    },

    /**
     * Vue.js instance methods
     *
     * @type {Object}
     */
    methods: {
        toggleNewsletterCheckbox() {
            const input = document.querySelector('.newsletter-checkbox');
            input.checked = !input.checked;
            this.newsletterCheckbox = !this.newsletterCheckbox;
        },

        /**
         * Initializes the newsletter form
         */
        onNewsletterSubmit(event) {
            if (this.newsletterSubmitting === false) {
                this.newsletterSubmitting = !this.newsletterSubmitting;
            }

            if (this.newsletterCheckbox === true) {
                const formData = new FormData(event.currentTarget);
                const headers = {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                };

                this.$validator.validateAll('newsletter-form').then((result) => {
                    if (result) {
                        const form = document.querySelector('.newsletter-form');
                        form.classList.add('submitting');

                        axios.post('/', formData, {
                            headers,
                        })
                            .then((response) => {
                                console.log(response);
                                if (response.data.success) {
                                    // success!
                                    form.classList.add('success');
                                } else {
                                    // Something went wrong
                                    form.classList.add('error');
                                }

                                form.classList.remove('submitting');
                            })
                            .catch((error) => {
                                // console.log(error);
                                form.classList.remove('submitting');
                            });
                    }
                });
            }
        },

        /**
         * Opens the Ticketlab iframe holder.
         *
         * @param {Event} event
         * @returns {Void}
         */
        openTicketHolder(event) {
            if (!this.showTicketOverlay) {
                this.iframe = document.createElement('iframe');
                const ticketHolder = document.getElementById('tickets-iframe-holder');
                const languageString = `https://tickets.lumiere.nl/language/?language=${document.documentElement.lang}&referer=`;
                this.iframe.src = languageString + encodeURIComponent(`${event.currentTarget.href}&user_token=${window.ticketlabId}`);
                ticketHolder.appendChild(this.iframe);

                setTimeout(() => {
                    iFrameResize({
                        onResized() {
                            window.scrollTo(0, 0);
                        },
                        heightCalculationMethod: 'lowestElement',
                    }, this.iframe);
                }, 1000);

                this.showTicketOverlay = !this.showTicketOverlay;
                // document.body.classList.add('overlay-open');

                const targetElement = document.querySelector('#tickets-overlay');
                targetElement.classList.add('active');
                // 3. ...in some event handler after showing the target element...disable body scroll
                bodyScrollLock.disableBodyScroll(targetElement);
                return;
            }

            this.closeTicketHolder();
        },

        /**
         * Closes the ticket holder and clears up the iframe.
         *
         * @returns {Void}
         */
        closeTicketHolder() {
            this.iframe.iFrameResizer.close();

            this.iframe = null;
            this.showTicketOverlay = false;
            // document.body.classList.remove('overlay-open');

            // 2. Get a target element that you want to persist scrolling for (such as a modal/lightbox/flyout/nav).
            const targetElement = document.querySelector('#tickets-overlay');
            targetElement.classList.remove('active');

            // 4. ...in some event handler after hiding the target element...
            bodyScrollLock.enableBodyScroll(targetElement);

            this.getCartAmount();
        },

        /**
         * Gets cart amount from session id
         */
        getCartAmount() {
            axios.get('/actions/site-module/lumiere/get-cart')
                .then((response) => {
                    // Check if there is quantity in the response
                    if (response.data.quantity) {
                        // If it's above 0, we can show the amount
                        this.cartAmount = (response.data.quantity > 0) ? response.data.quantity : null;
                        return;
                    }

                    this.cartAmount = null;
                })
                .catch((error) => {
                    if (this.debug) {
                        console.log(error);
                    }
                });
        },

        /**
         * Toggles the menu state.
         *
         * @return {Void}
         */
        toggleMenu() {
            if (this.showSearch) {
                this.showSearch = false;
            }

            if (this.showLanguageSelect) {
                this.showLanguageSelect = false;
            }

            this.showMenu = !this.showMenu;
            document.body.classList.toggle('overflow');

            this.$forceUpdate();
        },

        /**
         * Toggles the language selector.
         *
         * @return {Void}
         */
        toggleLanguageSelect() {
            if (this.showMenu) {
                this.showMenu = false;
            }

            if (this.showSearch) {
                this.showSearch = false;
            }
            this.showLanguageSelect = !this.showLanguageSelect;

            this.$forceUpdate();
        },

        /**
         * Toggles the share overlay.
         *
         * @return {Void}
         */
        toggleShareOverlay() {
            this.showOverlay = !this.showOverlay;

            this.$forceUpdate();
        },

        /**
         * Toggles the search
         *
         * @return {Void}
         */
        toggleSearch() {
            if (this.showMenu) {
                this.showMenu = false;
            }

            if (this.showLanguageSelect) {
                this.showLanguageSelect = false;
            }

            document.body.classList.toggle('overflow');
            this.showSearch = !this.showSearch;

            // Toggle focus
            if (this.showSearch) {
                document.getElementById('search-input').focus();
            }

            this.$forceUpdate();
        },

        toggleParentElementActiveState(event) {
            event.currentTarget.parentElement.classList.toggle('active');
        },

        toggleYearDropdown() {
            this.yearDropdown = !this.yearDropdown;
            this.monthDropdown = false;
        },

        toggleMonthDropdown() {
            this.monthDropdown = !this.monthDropdown;
            this.yearDropdown = false;
        },

        toggleFestivalEvent(event) {
            const el = document.getElementById(`event-content-${event}`);

            el.classList.toggle('active');

            if (!el.classList.contains('active')) {
                EventBus.$emit('close-festival-event', event);
            } else {
                EventBus.$emit('open-festival-event', event);
            }
        },

        toggleOverviewDatepicker() {
            EventBus.$emit('toggle-overview-datepicker');
        },

        toggleMainOption(event) {
            this.mainOption = !this.mainOption;
            this.subOption = false;
        },

        toggleSubOption(event) {
            this.subOption = !this.subOption;
            this.mainOption = false;
        },

        /**
         * Sets overlay cookie and hides the overlay
         *
         * @return {Void}
         */
        clickOverlay() {
            this.setCookie('cookie-message', 1, 45);
            this.showCookieMessage = false;
        },

        /**
         * Sets a given cookie
         *
         * @param {String} cname
         * @param {Number} cvalue
         * @param {Number} exdays
         */
        setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `${cname}=${cvalue}; ${expires}`;
        },

        /**
         * Gets a certain cookie
         *
         * @param  {String} cname
         * @return {String}
         */
        getCookie(cname) {
            const name = `${cname}=`;
            if (!document.cookie) {
                return false;
            }
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return true;
                }
            }
            return false;
        },

        /**
         * WIP: Toggles the press subscribe block
         * TODO: Finish, refactor, different approach, whatever...
         *
         * @return {Void}
         */
        togglePressSubscribe() {
            this.subscribe = document.getElementById('press-form-holder');
            this.showPressSubscribe = !this.showPressSubscribe;
            // this.subscribe.classList.toggle('open');

            if (!this.subscribe.classList.contains('open')) {
                TweenMax.to(this.subscribe, 1.2, {
                    className: '+=open',
                    ease: Quad.easeOut,
                });
                TweenMax.to('#pressletter', 0.4, { autoAlpha: 1 }, 0.1);
            } else {
                TweenMax.to(this.subscribe, 0.8, {
                    className: '-=open',
                    ease: Quint.easeOut,
                });
                TweenMax.to('#pressletter', 0.4, { autoAlpha: 0 }, 0.1);
            }
        },

        /**
         * WIP Handles submission of press form.
         *
         * @param {Event} event
         */
        onPressSubmit(event) {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    const formData = new FormData(event.currentTarget);
                    // formData.append('message['email')
                    axios.post('/', formData)
                        .then((response) => {
                            if (response.data.success) {
                                // success!
                                const form = document.querySelector('.cta.pressletter');
                                form.classList.add('success');
                            } else {
                                // Something went wrong
                                const form = document.querySelector('.cta.pressletter');
                                form.classList.add('error');
                            }
                        })
                        .catch((error) => {
                            // console.log(error);
                        });
                }
            });
        },

        /**
         * Raised when the user clicks on a time in the schedule.
         *
         * @param {Event} event
         */
        onTimeClicked(event) {
            EventBus.$emit('toggle-ticketholder', event);
        },
    },
});
